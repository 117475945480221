import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Halfpage from '../components/Advertising/Halfpage';
import Sidebar1Desktop from '../components/Advertising/Sidebar1Desktop';
import TopHomepage from '../components/Advertising/TopHomepage';
import TopHomepageMob from '../components/Advertising/TopHomepageMob';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import PostsPager from '../components/PostsPager';
import Top5Posts from '../components/Top5Posts.js';
import Top5Recipes from '../components/Top5Recipes';
import SeoPage from '../components/SEO/SeoPage';

const CategoryTemplate = props => {
  const {
    pageContext: { page },
  } = props;

  const { categoryData, categoryPosts, deAuthor } = props.data;

  const AllPosts = categoryPosts.edges.map(post => {
    if(!post.node.data.author.document){
      post.node.data.author = deAuthor
      post.node.data.author.slug = 'dediabetes'
    }
    else
    {
      let author_id = post.node.data.author.slug
      post.node.data.author = post.node.data.author.document
      post.node.data.author.slug = author_id
    }
    return post
  })


  const catname = categoryData.data.name.text ? categoryData.data.name.text : 'no name';
  const catdesc = categoryData.data.description.text ? categoryData.data.description.text : 'no desc';
  const page2 = page + 1;

  console.log(catname)
  const Heading = styled.h1`
    ${tw`font-bold text-6xl text-center`}
  `;

  return (
    <Layout>
      <Hero />
      <SeoPage
        title={`Articulos de la categoría ${catname} - Pagina ${page2}`}
        description={`${catdesc}`}
      />
      <div className="md:flex flex-row justify-center m-6 hidden md:visible">
        <TopHomepage />
      </div>
      <div className="flex flex-row justify-center mt-6 md:hidden ">
        <TopHomepageMob />
      </div>
      <div className="lg:px-24 lg:py-4">
      <Heading className="leading-snug mb-10 text-center capitalize">
            {catname}
          </Heading>
          <p className="italic px-2 md:px-3 lg:px-6">{catdesc}</p>
        </div>
      <div className="grid grid-cols-blog w-full container mx-auto gap-x-4">
        <div className="col-span-2 md:col-span-1 mx-autoleading-losse mx-24 my-6">

          <PostsPager pageContext={props.pageContext} posts={AllPosts} />
        </div>

        <aside
          className="hidden md:block md:visible mx-autoleading-losse bg-white my-6"
          style={{ marginBottom: '110px' }}
        >
          {/* <SubscribeSide /> */}
          <div className="">
            <Sidebar1Desktop />
          </div>
          <Top5Recipes />
          <Top5Posts />
          <div className="my-3">
            <Halfpage />
          </div>
        </aside>
      </div>
    </Layout>
  );
};
export const query = graphql`
  query($skip: Int!, $limit: Int!, $category: String!) {    
    deAuthor : prismicAuthor(uid: {eq: "dediabetes"}) {
      data {
        name {
          text
        }
        description_short {
          text
        }
        headshot {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    categoryPosts : allPrismicBlogPost(
      skip: $skip
      limit: $limit
      sort: {fields: data___release_date, order: DESC}
      filter: {data: {categories: {elemMatch: {category: {uid: {eq: $category}, lang: {eq: "es-es"}}}}, author: {uid: {ne: null}}}}
      ) {
      edges {    
        node {
          uid
          data {
            title {
              text
            }
            description {
              text
            }
            author {
              slug : uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name {
                      text
                    }
                    description_short {
                      text
                    }
                    headshot {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
            type
            release_date(formatString: "MMM DD, YYYY", locale: "es-ES")
            categories {
              category {
                slug : uid
                document {
                  ... on PrismicCatego {
                    id
                    data {
                      name {
                        text
                      }
                      label {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    categoryData : prismicCatego(uid: {eq: $category}) {
      data {
        name {
          text
        }
        label {
          text
        }
        description {
          text
        }
      }
    }
  }
`;

export default CategoryTemplate;
